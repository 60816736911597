<template>
  <FocusTrap>
    <div class="card" id="mycard">

<!--    <div class="card-header">-->
<!--      <h4 class="card-title"> <i class="icon-gear"></i> Settings</h4>-->
<!--    </div>-->

      <div class="card-body">

      <div class="d-lg-flex">
        <ul class="nav nav-tabs nav-tabs-vertical flex-column mr-lg-3 wmin-lg-200 mb-lg-0 border-bottom-0">
          <li class="nav-item"><a href="#vertical-left-tab1" class="nav-link active" data-toggle="tab"><i class="icon-city mr-2"></i>Institution</a></li>
          <li class="nav-item"><a href="#vertical-left-tab2" class="nav-link" data-toggle="tab"><i class="icon-books mr-2 text-warning-600"></i>Library</a></li>
          <li class="nav-item"><a href="#vertical-left-tab3" class="nav-link" data-toggle="tab"><i class="icon-gear mr-2 text-green-800"></i>General</a></li>
        </ul>

        <div class="tab-content flex-lg-fill">
          <div class="tab-pane fade active show" id="vertical-left-tab1">

              <div class="card">

                <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 12px;" >
                  <h5 class="card-title">Institution Information</h5>
                  <div class="header-elements">
                    <div class="list-icons">
                      <!--                    <a class="list-icons-item" data-action="remove" ></a>-->
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <p/>
                  <form action="#">

                    <div class="form-group row">
                      <label class="col-md-2 col-form-label">Name:</label>
                      <div class="col-md-10">
                        <input ref="txtname" class="form-control" v-if="company" v-model="company.name" autocomplete="off" required/>
                      </div>
                    </div>


                    <div class="form-group row">
                      <label class="col-md-2 col-form-label">Street:</label>
                      <div class="col-md-10">
                        <textarea class="form-control" v-if="company" v-model="company.street" rows="1" autocomplete="off"/>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-2 col-form-label">Landmark:</label>
                      <div class="col-md-10">
                        <input class="form-control" v-if="company" v-model="company.landmark" autocomplete="off"/>
                      </div>
                    </div>


                    <div class="form-group row">
                      <label class="col-md-2 col-form-label">City:</label>
                      <div class="col-md-4">
                        <input class="form-control" v-if="company" v-model="company.city" autocomplete="off"/>
                      </div>

                      <label class="col-md-1 col-form-label">PIN:</label>
                      <div class="col-md-5">
                        <input  class="form-control" v-if="company" v-model="company.pin" autocomplete="off" />
                      </div>
                    </div>


                    <div class="form-group row">
                      <label class="col-md-2 col-form-label">Tel:</label>
                      <div class="col-md-4">
                        <input class="form-control" v-model="company.tel" autocomplete="off"/>
                      </div>

                      <label class="col-md-1 col-form-label">Mobile:</label>
                      <div class="col-md-5">
                        <input class="form-control" v-model="company.mobile" autocomplete="off"/>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-2 col-form-label">Email:</label>
                      <div class="col-md-4">
                        <input class="form-control" v-model="company.email" autocomplete="off"/>
                      </div>

                      <label class="col-md-1 col-form-label">Web:</label>
                      <div class="col-md-5">
                        <input class="form-control" v-model="company.web" autocomplete="off"/>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-md-2 col-form-label">GSTIN:</label>
                      <div class="col-md-4">
                        <input class="form-control" v-if="company" v-model="company.gstin" maxlength="15" autocomplete="off" required/>
                      </div>

                      <label class="col-md-1 col-form-label">State:</label>
                      <div class="col-md-5">
                        <select data-placeholder="Select your state" class="form-control" v-if="company" v-model="company.state_cd" autocomplete="off">
                          <option value="37">Andhra Pradesh</option>
                          <option value="35">Andaman and Nicobar Islands</option>
                          <option value="12">Arunachal Pradesh</option>
                          <option value="18">Assam</option>
                          <option value="10">Bihar</option>
                          <option value="04">Chandigarh</option>
                          <option value="22">Chhattisgarh</option>
                          <option value="26">Dadar and Nagar Haveli</option>
                          <option value="25">Daman and Diu</option>
                          <option value="07">Delhi</option>
                          <option value="30">Goa</option>
                          <option value="24">Gujarat</option>
                          <option value="06">Haryana</option>
                          <option value="02">Himachal Pradesh</option>
                          <option value="01">Jammu and Kashmir</option>
                          <option value="20">Jharkhand</option>
                          <option value="29">Karnataka</option>
                          <option value="32">Kerala</option>
                          <option value="31">Lakshadweep</option>
                          <option value="23">Madhya Pradesh</option>
                          <option value="27">Maharashtra</option>
                          <option value="14">Manipur</option>
                          <option value="17">Meghalaya</option>
                          <option value="15">Mizoram</option>
                          <option value="13">Nagaland</option>
                          <option value="21">Odisha</option>
                          <option value="99">Over Seas</option>
                          <option value="34">Pondicherry</option>
                          <option value="03">Punjab</option>
                          <option value="08">Rajasthan</option>
                          <option value="11">Sikkim</option>
                          <option value="33" selected>Tamil Nadu</option>
                          <option value="36">Telangana</option>
                          <option value="16">Tripura</option>
                          <option value="09">Uttar Pradesh</option>
                          <option value="05">Uttarakhand</option>
                          <option value="19">West Bangal</option>
                        </select>
                      </div>
                    </div>


                  </form>
                </div>

                <div class="card-footer">
                  <div class="text-right" >
<!--                    <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>-->
                    <SaveButton title="Save" @handle_save_action="save_default_company"/>
                  </div>
                </div>
              </div>

          </div>

          <div class="tab-pane fade" id="vertical-left-tab2">

              <div class="row">

                <div class="col-lg-4">
                  <div class="card card-body border-danger-400" id="student_card" >

                    <div class="text-center">
                      <h6 class="mb-0 font-weight-semibold">Student Circulation</h6>
                    </div>

                    <div class="border rounded p-3">

                        <form action="#">

                          <div class="form-group">
                            <label>Max Allowed Circulations</label>
                            <input type="number" class="form-control" min="1" maxlength="30" placeholder="Allowed books Count" v-model="student.max_circulations">
                          </div>

                          <div class="form-group">
                            <label>Due Days</label>
                            <input type="number" class="form-control" min="1" maxlength="30" placeholder="Due days" v-model="student.due_days">
                          </div>

                          <div class="form-group">
                            <label>Penalty / Day</label>
                            <input type="number" class="form-control" placeholder="Penalty per Day after due date" v-model="student.penalty">
                          </div>


                          <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-outline-success-100" @click="perform_student_staff_settings"><i class="icon-database-add mr-2"></i>Update </button>
                          </div>
                        </form>

                    </div>

                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card card-body border-danger-400" id="staff_card" >

                    <div class="text-center">
                      <h6 class="mb-0 font-weight-semibold">Staff Circulation</h6>
                    </div>

                    <div class="border rounded p-3">
                      <focus-trap>
                        <form action="#">

                          <div class="form-group">
                            <label>Max Allowed Circulations</label>
                            <input type="number" class="form-control" min="1" maxlength="30" placeholder="Allowed books Count" v-model="staff.max_circulations">
                          </div>

                          <div class="form-group">
                            <label>Due Days</label>
                            <input type="number" class="form-control" min="1" maxlength="30" placeholder="Due days" v-model="staff.due_days">
                          </div>

                          <div class="form-group">
                            <label>Penalty / Day</label>
                            <input type="number" class="form-control" placeholder="Penalty per Day after due date" v-model="staff.penalty">
                          </div>

                          <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-outline-success-100" @click="perform_save_staff_settings"><i class="icon-database-add mr-2"></i>Update </button>
                          </div>

                        </form>
                      </focus-trap>
                    </div>

                  </div>
                </div>

              </div>


          </div>

          <div class="tab-pane fade" id="vertical-left-tab3">


          </div>

        </div>
      </div>

    </div>
  </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'

export default {
  name: 'Settings',
  components: { SaveButton },
  store,
  data() {
    return {
      company: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","landmark":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":"","state_cd":33}'),
      student : {"id":0,"max_circulations":0,"due_days":0,"penalty":0},
      staff : {"id":0,"max_circulations":0,"due_days":0,"penalty":0},
    }
  },
  mounted () {
    const self = this;
    self.load_default_company();
    self.load_student_settings();
    self.load_staff_settings();
  },
  methods:{
    load_student_settings(){
      const self = this;

      // student = {"id":0,"max_circulations":0,"due_days":0,"penalty":0}

      const requestOptions = {
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#student_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/settings/student`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.student = resp.data;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(()=>{
        $('#student_card').unblock();
      });
    },
    perform_student_staff_settings(){
      const self = this;
      try {
        self.$data.student.id = 1;
        self.$data.student.max_circulations = parseInt(self.$data.student.max_circulations);
        self.$data.student.allowed_books = parseInt(self.$data.student.allowed_books);
        self.$data.student.penalty = parseInt(self.$data.student.penalty);

        if (self.$data.student.max_circulations <= 0) {
          alert("Invalid Circulations");
          return
        } else if (self.$data.student.allowed_books <= 0) {
          alert("Invalid Max Books Count");
          return
        } else if (self.$data.student.penalty < 0) {
          alert("Invalid Penalty");
          return
        }

        const requestOptions = {
          method: 'POST',
          mode: "cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.student)
        };

        $('#student_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/settings/student`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: "Success!", text: resp.msg, type: "success", timer: 1500 });

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(() => {
          $('#student_card').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
    load_staff_settings(){
      const self = this;

      const requestOptions = {
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#staff_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/settings/staff`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.staff = resp.data;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(()=>{
        $('#staff_card').unblock();
      });
    },
    perform_save_staff_settings(){
      const self = this;
      try {
        self.$data.staff.id = 1;
        self.$data.staff.max_circulations = parseInt(self.$data.staff.max_circulations);
        self.$data.staff.allowed_books = parseInt(self.$data.staff.allowed_books);
        self.$data.staff.penalty = parseInt(self.$data.staff.penalty);

        if (self.$data.staff.max_circulations <= 0) {
          alert("Invalid Circulations");
          return
        } else if (self.$data.staff.allowed_books <= 0) {
          alert("Invalid Max Books Count");
          return
        } else if (self.$data.staff.penalty < 0) {
          alert("Invalid Penalty");
          return
        }

        const requestOptions = {
          method: 'POST',
          mode: "cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.staff)
        };

        $('#staff_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/settings/staff`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: "Success!", text: resp.msg, type: "success", timer: 1500 });

          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(() => {
          $('#staff_card').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
    load_default_company(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/companies`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(_.isArray(resp.data)){
              resp.data.forEach((cmp)=>{
                self.$data.company = cmp;
              });
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $('#mycard').unblock();
        });


    },
    save_default_company(){
      const self = this;

      self.$data.company.state_cd = parseInt(self.$data.company.state_cd);
      if(self.$data.company.name.trim().length < 1){
        alert("Invalid Name");
        return
      }else if(self.$data.company.state_cd <= 1){
        alert("Invalid State");
        return
      }else if(self.$data.company.gstin <= 1){
        alert("Invalid GSTIN");
        return
      }

      console.log(JSON.stringify(self.$data.company));

      const requestOptions = {
        method:  (self.$data.company.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.company)
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      fetch(`${process.env.VUE_APP_ROOT_API}api/company`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500  });
          self.$emit('company_saved', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(()=>{
        $('#mycard').unblock();
      });
    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00 !important;
  }
</style>
